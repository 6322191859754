
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import MenuMapper, { Link } from '@/helpers/mappers/menu-mapper';
import SidebarMenuMapper, { Header as SidebarHeader } from '@/helpers/mappers/sidebar-menu-mapper';
import GlobalSearch from '@/components/commonComponents/GlobalSearch.vue';
import Message from '@/components/mixins/Message.vue';
import SidebarCaption from '@/components/commonComponents/SidebarCaption.vue';
import useTenantSettingsStore from '@/stores/tenantSettings';
import useAuthStore from '@/stores/auth';
import SidebarMenuItem from '@/components/commonComponents/SidebarMenuItem.vue';

@Component({
  name: 'Header',
  components: {
    SidebarMenuItem,
    GlobalSearch,
    SidebarCaption,
  },
  data: () => ({
    subMenu: {},
  }),
})
export default class Header extends Vue {
  private tenantSettingsStore = useTenantSettingsStore();

  private authStore = useAuthStore();

  private drawer = false;

  private showSearch = false;

  private mainMenu: Array<Link> = [];

  private mainMenuSub: { [key: string]: Array<Link|SidebarHeader> } = {};

  private secondaryMenu: Array<Link> = [];

  private loading = false;

  private refreshMobileMenu = 0;

  private expandedMenuItem: number|null = null;

  protected screenSize: 'mobile'|'desktop'|'undefined' = 'undefined';

  protected mounted(): void {
    this.fetchData().then(() => this.checkForMenuType());
  }

  protected toggleMenu(index: number, eventType: 'click'|'hover'|'mouseleave'): void {
    const currentActiveMainMenuIndex = this.mainMenu.findIndex((mainMenuItem) => mainMenuItem.isExpanded);

    if (currentActiveMainMenuIndex !== -1 && (currentActiveMainMenuIndex !== index || eventType === 'mouseleave')) {
      ((this.$refs.headerItems as Array<Vue>)[currentActiveMainMenuIndex]?.$el as HTMLElement).click();
      this.mainMenu[currentActiveMainMenuIndex].isExpanded = false;
    }

    // Check for sub items (= mega menu)
    if (this.mainMenu[index]?.items?.[0] === undefined) {
      return;
    }

    if (eventType === 'click') {
      this.mainMenu[index].isExpanded = !this.mainMenu[index].isExpanded;
    }
    if (eventType === 'hover') {
      ((this.$refs.headerItems as Array<Vue>)[index]?.$el as HTMLElement).click();
    }
  }

  private checkForMenuType(): void {
    setTimeout(() => {
      const headerItems = this.$refs.headerItems as Array<Vue>;
      const rightLocationOfLastHeaderItem = headerItems.pop()?.$el.getBoundingClientRect().right as number;

      this.setScreenSize(rightLocationOfLastHeaderItem);

      window.addEventListener('resize', () => this.setScreenSize(rightLocationOfLastHeaderItem));
    }, 200);
  }

  private setScreenSize(rightPositionOfLastElement: number): void {
    const maxRightPosition = window.innerWidth - 100;

    this.screenSize = maxRightPosition < rightPositionOfLastElement
      ? 'mobile'
      : 'desktop';
  }

  searchbox(): void {
    this.showSearch = !this.showSearch;
  }

  private logout(): void {
    this.$router.push('/logout');
  }

  private static getUrl(url: string): string {
    const urlArray = url.split('/');
    let newUrl = '';

    if (urlArray.length === 2) {
      // URLs like /dashboard
      newUrl = url;
    } else if (urlArray.length === 4) {
      // Like actions and reports
      newUrl = `/${urlArray[1]}`;
    } else if (urlArray.length > 4) {
      // All dossiers
      newUrl = `/${urlArray[1]}/${urlArray[2]}`;
    }

    return newUrl;
  }

  private getButtonClass(item: Link): string {
    let mainUrl = [Header.getUrl(item.to)];
    const routeUrl = Header.getUrl(this.$route.path);

    if (item.items?.length && item.items?.length > 0) {
      mainUrl = item.items.map((items) => Header.getUrl(items.to));
    }

    return mainUrl.includes(routeUrl)
      ? 'v-btn--active'
      : 'btn-active';
  }

  private async fetchData(fetchPolicy: FetchPolicy = 'cache-first'): Promise<void> {
    this.loading = true;

    const variables = {
      mainMenuName: 'main',
      secondaryMenuName: 'secondary',
    };

    return import('@/graphql/queries/menu')
      .then(({ MENUS: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then(({ data: { mainMenu, secondaryMenu } }) => {
        const menuMapper = new MenuMapper();

        this.mainMenu = menuMapper.map(mainMenu.items);
        this.secondaryMenu = menuMapper.map(secondaryMenu.items);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async getSubMenu(navigationLink: Link, fetchPolicy: FetchPolicy = 'network-only'): Promise<void> {
    if (this.mainMenuSub[navigationLink.title] === undefined) {
      const sidebarMapper = new SidebarMenuMapper();

      const navigationLinkParts = navigationLink.to.split('/');

      const variables = {
        name: navigationLinkParts[1],
        context: navigationLinkParts[navigationLinkParts.length - 2],
        slug: navigationLinkParts[navigationLinkParts.length - 1],
        addCounters: true,
      };

      this.loading = true;
      await import('@/graphql/queries/menu')
        .then(({ MENU: query }) => this.$apollo.query({
          fetchPolicy,
          query,
          variables,
        }))
        .then((response) => {
          sidebarMapper.input = response.data.menu.items;
          sidebarMapper.map();
          this.mainMenuSub[navigationLink.title] = sidebarMapper.items;
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.refreshMobileMenu += 1;
  }
}
