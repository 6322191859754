
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import Header from '@/layouts/header/Header.vue';

@Component({
  name: 'FullLayout',
  components: {
    Header,
  },
})
export default class FullLayout extends Vue {
}
