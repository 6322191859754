
import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import Debouncer from '@/helpers/debouncer';
import {
  Model,
  Options,
  Schema,
  Valid,
} from '@/types/vjsf';
import DataTableHandler from '@/components/DataTable/DataTable';
import Message from '@/components/mixins/Message.vue';
import { MetadataRowSetting } from '@/types/DataTableTypes';
import DataTable from '@/components/DataTable/DataTable.vue';

// Lazy loading imports
const VJsfForm = () => import(
  /* webpackChunkName: "v-jsf-form" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/VJsfForm.vue'
);
const DataTableCell = () => import(
  /* webpackChunkName: "data-table-cell" */
  /* webpackPrefetch: false */
  '@/components/DataTable/DataTableCell/DataTableCell.vue'
);

@Component({
  name: 'GlobalSearch',
  components: { DataTable, DataTableCell, VJsfForm },
  data: () => ({
    model: { } as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null as Valid,
  }),
})
export default class GlobalSearch extends Vue {
  private dataTable = new DataTableHandler();

  private Debouncer = new Debouncer(300);

  private showSearchBar = false;

  private focusOnRow: null|number = null;

  private loading = false;

  private buttonName = '';

  protected mounted(): void {
    this.dataTable.itemsPerPageOptions = [20];
    this.fetchData();

    window.addEventListener('keydown', (event) => {
      if (['ArrowUp', 'ArrowDown', 'Escape', 'Tab'].includes(event.code) && this.showSearchBar) {
        this.handleKeyInput(event.code);
        event.preventDefault();
      }
    }, false);
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first') {
    this.loading = true;

    import('@/graphql/queries/global-search-form')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
      }))
      .then((response) => {
        const { model, options } = response.data.searchForm;
        const { schema, buttonName } = GlobalSearch.removeSubmitButtonFromSchema(response.data.searchForm.schema);
        schema.properties.query['x-props'] = { ...schema.properties.query['x-props'], ...{ solo: true, autofocus: true } };
        this.$data.schema = schema;
        this.$data.model = model;
        this.$data.options = options;
        this.buttonName = buttonName;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private handleKeyInput(key: 'Escape'|'ArrowUp'|'ArrowDown'|string): void {
    switch (key) {
      case 'Escape': this.closeSearchBar(false);
        break;
      case 'ArrowDown':
      case 'Tab':
        this.refocus(1);
        break;
      case 'ArrowUp': this.refocus(-1);
        break;
      default: break;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private navigateTo(rowSetting: unknown|MetadataRowSetting): void {
    const url = (rowSetting as {link: {url: string|undefined}})?.link?.url;
    if (url !== undefined) {
      window.location.href = url;
    }
  }

  private styleText(string: string): string {
    return string.replace(new RegExp(`(${Object.values(this.$data.model)[0]})`, 'gi'), '<b><u>$1</u></b>');
  }

  private closeSearchBar(keepResult = true): void {
    if (this.showSearchBar) {
      this.showSearchBar = false;
      this.focusOnRow = null;
      if (!keepResult) {
        this.dataTable.rows = [];
      }
    }
  }

  private openSearchBar(): void {
    this.showSearchBar = true;
  }

  private refocus(direction: -1|1): void {
    const totalRows = this.dataTable.rows.length;

    this.focusOnRow = (this.focusOnRow !== null)
      ? (this.focusOnRow + totalRows + direction) % totalRows
      : 0;

    const row = this.$refs[`searchRowIndex-${this.focusOnRow}`] as HTMLElement;
    this.$nextTick(() => {
      row.focus();
    });
  }

  @Watch('model', { deep: true })
  private modelWatcher(): void {
    this.dataTable.resetCurrentPage();
    this.search();
  }

  private search(): void {
    this.Debouncer.debounce(() => {
      if (this.$data.model.query !== null && this.$data.model.query.length >= 2) {
        this.getResults(this.$data.model);
      }
    });
  }

  private getResults(queryVariables: { [key: string]: string }, fetchPolicy: FetchPolicy = 'no-cache') {
    this.loading = true;

    const variables = {
      ...this.dataTable.getQueryVariables(),
      ...queryVariables,
    };

    import('@/graphql/queries/global-search')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        const { data } = response;
        data.list.dataGridInfo.metadata['column-settings'] = {};
        data.list.dataGridInfo.columns.forEach(({ name }: { name: string }) => {
          data.list.dataGridInfo.metadata['column-settings'][name] = {
            type: 'search',
            cell_value: Object.values(this.$data.model)[0],
          };
        });
        this.dataTable.handleData(data);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  // This is a temp function. The submitbuttom should be removed from the vjsf component.
  private static removeSubmitButtonFromSchema(schema: Schema): { schema: Schema, buttonName: string } {
    const filteredSchema: Schema = schema;
    let buttonName = '';

    // Loop through all the properties
    Object.keys(filteredSchema.properties).forEach((key) => {
      const property = filteredSchema.properties[key];

      if (property['x-props']?.type === 'submit') {
        buttonName = property.title ?? '';
        delete filteredSchema.properties[key];
      }
    });

    return { schema: filteredSchema, buttonName };
  }
}
