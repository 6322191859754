export default class Debouncer {
  private timer: NodeJS.Timeout|null = null;

  private readonly delay: number = 0;

  constructor(delay = 0) {
    this.delay = delay;
  }

  public debounce(fn: () => void, delay: number|null = null): void {
    this.resetTimer();
    this.timer = setTimeout(() => {
      fn();
    }, typeof delay === 'number' ? delay : this.delay);
  }

  private resetTimer(): void {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
  }
}
