import {
  NavigationItem as Item,
  NavigationInput as Input,
  NavigationLink as Link,
} from '@/helpers/mappers/mapper';

export { Link };

export default class MenuMapper {
  public map(input: Input): Array<Link> {
    return input.map((value: Item, _: number, __: Array<Item>) => this.translateMenuItem(value));
  }

  private translateMenuItem(item: Item): Link {
    const to = item.url;

    const mappedItem: Link = { title: item.label, to, isExpanded: false };

    if (item.metadata?.icon !== undefined) {
      mappedItem.icon = item.metadata.icon;
    }

    if (item.items instanceof Array) {
      mappedItem.items = item.items.map((items) => this.translateMenuItem(items));
    }

    return mappedItem;
  }
}
